import React, { Component } from 'react';
import { fadeIn, fadeInRight } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import './Slide.css';
import './Pageh.css';
import Message from  'antd/lib/message'

// var uni = require('../utils/uni')

class Pageh extends Component {

  constructor(props) {
    super(props);
    this.state = {
      radioImage: require('../images/page1/radio1.png'),
      canOpen: false,
    };
  }

  test = () => {
    console.log('in test');
    // uni.postMessage({
    //   data: {
    //     action: 'tesss'
    //   }
    // });

  };
  onChangXYAgree = () => {
    let { radioImage, canOpen } = this.state;
    canOpen = !canOpen;
    console.log('canOpen is ',canOpen)
    if (canOpen) {
      radioImage = require('../images/page1/radio2.png');
    } else {
      radioImage = require('../images/page1/radio1.png');
    }
    this.setState({
      radioImage,
      canOpen,
    });
  };

  goXY = () => {
    console.log('in go xy');
    document.dispatchEvent(new CustomEvent("goXY",{bubbles:!0,cancelable:!0}))

  };

  openReport=()=>{
    if(this.state.canOpen){
      console.log('go open')
      this.props.onConfirmXY()
      document.dispatchEvent(new CustomEvent("play",{bubbles:!0,cancelable:!0}))

    }else {
      Message.info('请同意授权协议')
      console.log('can not open')

    }
  }
  render() {
    const styles = {
      fadeInUp1s: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeInRight, 'fadeInRight'),
      },
      fadeInUp1_5s: {
        animation: 'x 4s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },

    };
    return (
      <StyleRoot>

        <div className='section page pageh'>
          <div className='dibu'>
            <div className='open-button' onClick={this.openReport}/>
            <div className='xy-area'>
              <div className='xy' onClick={this.onChangXYAgree}>
                <img className='h-radio' src={this.state.radioImage}></img>
                <div className='xy1'>同意星斗推查询并统计我的使用数据</div>
              </div>

              <div className='xy2' onClick={this.goXY}>《授权协议》</div>
            </div>
          </div>

          <div className="meteors">
            <span className="meteor meteor-1"/>
            <span className="meteor meteor-2"/>
          </div>
          {/*<div className='logo'/>*/}
          <div className='titlex'>
            <div className='title1' style={styles.fadeInUp1_5s}/>
            <div className='title2' style={styles.fadeInUp1s}/>
          </div>
          <div className='stars'>
            <div className='star1'/>
            <div className='star2'/>
          </div>
          <div className='global'>
            <div className='qiu1'/>
            <div className='qiu2'/>
            <div className='xxx1'/>
            <div className='xxx2'/>
          </div>
          <div className='ladders'>
            <div className='man'/>
            <div className='ladder'/>
          </div>
        </div>


      </StyleRoot>
    );
  }
}

export default Pageh;

import React, { Component } from 'react';
import './App.css';


import Alert from 'antd/lib/alert';
import Spin from 'antd/lib/spin';
import Slide from './components/Slide';
import LoadingPage from './components/LoadingPage';
import {SERVER_HOST} from './utils/constant'
import axios from 'axios';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failed: false,
      loading: true,
      firstPage: true,
      viewOther: false,
      viewOtherNot: false,
      status: '正在生成您的年终报告',
      errMsg:'xxx'
    };
    this.info = null;
    console.log('in app test')
  }

  componentDidMount() {
    console.log('in app componentDidMount',  this.props.match.params.tdata,'dev is ',process.env.NODE_ENV)
    this.run();
  }

  componentWillUnmount() {

  }

  run = async() => {
    await this.fetchInfo();
  }

  fetchInfo = async() =>  {
    const tdata = this.props.match.params.tdata
    if(!tdata){
      this.setState({loading: false, firstPage: true, failed: true});
    }
    // return
    let request =  axios.create({
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json', // 默认值
        'Authorization': `Bearer ${tdata}`,
        'x-version': 'web',
        'x-app-no':''
      }
    })
    const url = SERVER_HOST + "/auth/annual_report"
    console.log('url is ',url)
    request.get(url).then((res)=>{
      console.log('report data is ',res)
      if(res.data && res.data.code == 200){
        this.info = res.data.data;
        this.setState({loading: false, firstPage: false});
      }else{
        this.setState({loading: false, firstPage: true, failed: true});
      }
    }).catch(err=>{
      this.setState({
        errMsg:JSON.stringify(err)

      })
      this.setState({loading: false, firstPage: true, failed: true});
    })

    // this.setState({loading: false, firstPage: false});
    // return
    // const that = this;
    //
    // let url = SERVER_HOST + "/data/userinfo/" + qq + "/" + name + "/" + password
    // axiosJSON.get(url).then((res) => {
    //   // that.setState({info: res.data.user});
    //   if (res.data.finish === 1) {
    //       that.info = res.data.user;
    //       console.log(that.info);
    //
    //       that.setState({loading: false, firstPage: false});
    //     }
    //     else {
    //       that.setState({loading: false, firstPage: true, failed: true});
    //     }
    //   }
    //   ).catch(err=>{
    //     console.log('in fetch err')
    // });
  }

  render() {
    console.log('in slide render');
    let loadingPage = null;
    if (this.state.loading) {
      loadingPage = <LoadingPage />;
    }
    const styles = {
      firstPage: {
      },
    };
    // return  (
    //   <div className='App'>
    //     <LoadingPage />
    //   </div>
    // )
    return (
      <div className="App">
        <audio id="audio_player" >
          <source id="src_mp3" type="audio/mp3" src="https://spreadcdn.hikoon.com/audio/yearReport/year.mp3" />
        </audio>
        {this.state.firstPage ? (
          <div className="firstPage" style={styles.firstPage}>
            {this.state.failed ? (
              <Alert
                className="failed"
                message= {'获取数据失败请返回重试'}
                type="error"
                closable
                afterClose={this.onClose}
                banner
              />
            ) : null}
            {loadingPage}
          </div>
        ) : (
          <Slide info={this.info}/>
        )}
      </div>
    );
  }
}

export default App;

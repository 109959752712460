import React, { Component } from 'react';
import { fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import './Slide.css';
import './Pageh5.css';

class Pageh5 extends Component {
  render() {

    const styles = {
      fadeIn1s: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },
      fadeIn1_5s: {
        animation: 'x 3s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },
      fadeInUp2s: {
        animation: 'x 4s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },

    };
    let enrol_activity_count = ''
    let enrol_activity_price = ''
    let max_activity_name = ''
    if(this.props && this.props.info){
      enrol_activity_count = this.props.info.enrol_activity_count
      enrol_activity_price = Math.floor(this.props.info.enrol_activity_price/100)
      max_activity_name = this.props.info.max_year_activity.song_name
    }

    return (
      <StyleRoot>
        <div className='section page pageh-5'>
          {/*<div className='light'></div>*/}
          <div className='bg-area'>
            <div className='flyman'/>
            <div className='qiu'/>
            <div className='qi2'/>
            <div className='huan'/>
            <div className='star'/>
          </div>
          {this.props.page === 5?<div className='text-area'>
            <div className='title' style={styles.fadeIn1s}>收益最多</div>
            <div className='section1' style={styles.fadeIn1_5s}>
              <div className='text1'>一年来</div>
              <div className='text-row'>
                <div className='text1'>你共计参与</div>
                <div className='text2'>{enrol_activity_count}</div>
                <div className='text1'>个推广活动</div>

              </div>
              <div className='text-row'>
                <div className='text1'>共结算了</div>
                <div className='text2'>{enrol_activity_price}</div>
                <div className='text1'>元收益</div>

              </div>
            </div>
            <div className='section2' style={styles.fadeInUp2s}>
              <div className='text-row'>
              <div className='text1'>收益最多的歌是 </div>
                <div className='text1 song' style={{color:'white'}}> {max_activity_name}</div>
              </div>
              <div className='text1'>希望未来的日子 在音乐的陪伴下</div>
              <div className='text1'>你能拥有更多的收获~</div>
            </div>
          </div>:null}

        </div>

      </StyleRoot>
    );
  }
}

export default Pageh5;

import React, { Component } from 'react';
import { fadeInRight ,fadeIn} from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import './LoadingPage.css'


class LoadingPage extends Component {

  render() {
    const styles = {
      fadeInUp1s: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeInRight, 'fadeInRight'),
      },
      fadeInUp1_5s: {
        animation: 'x 4s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },

    };
    return (
      <StyleRoot>

        <div className='section page loadingPage'>
          <div className='h-center'>
            <div className='bp'>
              <div className='bp1'></div>
              <div className='bp2'></div>
            </div>
            <div className='h-title'>你的2022年度推歌报告生成中...</div>
          </div>
          <div className='logo-area'>
            <div className='logo'></div>
          </div>


        </div>

        {/*<div className='test-button' style={{position:'absolute',top:'100px',left:'50px',width:'40px',height:'100px'}}>test</div>*/}

      </StyleRoot>
    );
  }
}

export default LoadingPage;

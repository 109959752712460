import React, { Component } from 'react';
import { fadeIn, fadeInLeft, fadeInRight } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import './Slide.css';
import './Pageh6.css';

class Pageh6 extends Component {
  componentDidMount() {
    document.dispatchEvent(new CustomEvent("share",{bubbles:!0,cancelable:!0}))
  }


  render() {

    const styles = {
      fadeIn1s: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },
      fadeIn1_5s: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },
      fadeInUp2s: {
        animation: 'x 4s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },

    };
    let checkNum = 6
    if(!this.props.info.enrol_activity_price){
      checkNum = 4
    }
    return (
      <StyleRoot>
        <div className='section page pageh-6'>

          {this.props.info ?  <img src={this.props.info.user.qr} style={styles.fadeIn1s} className='qr'/>:null}
          <div className='fu'>斗赚薪宜，梦想启航...</div>
          <div className='bg-area'>

            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              {/*<div className='yuan'/>*/}
              {/*<div className='qius'/>*/}
              <div className='earth'/>
              <div className='book'/>
              <div className='man'/>
              <div className='rr'></div>
              <div className='yf'></div>

              <div className='stars'>
                <div className='star1'/>
                <div className='star2'/>
                <div className='star3'/>
                <div className='star4'/>
              </div>
            </div>
          </div>
          {this.props.page === checkNum?<div className='text-area'>
            <div className='title' style={styles.fadeIn1s}>或许你是</div>
            <div className='section1' style={styles.fadeIn1_5s}>
              <div className='text1'>丰富情感思维的细腻达人</div>
              <div className='text1'>敏锐捕捉力的流量收割达人</div>
              <div className='text1'>优越乐感与表达能力的创作型达人</div>
              <div className='text1'>唱跳俱佳的全能型达人</div>
              <div className='text1'>无限才华与潜力的音乐人......</div>
            </div>
            <div className='section2' style={styles.fadeInUp2s}>
              <div className='text1 text2'>无论是什么身份</div>
              <div className='text1 text2'>音乐始终都是我们情绪的表达</div>
              <div className='text1 text2'>它融入到我们的生命中</div>
              <div className='text1 text2'>成为理想长途中珍贵的宝藏</div>
            </div>
          </div>:null}
          <div className='share-button' onClick={this.goShare}></div>

        </div>


      </StyleRoot>
    );
  }
}

export default Pageh6;

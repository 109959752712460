import React, { Component } from 'react';

import './net_main.css';

import Swiper from 'react-id-swiper';
import { fadeInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import Pageh from './Pageh';
import Pageh2 from './Pageh2';
import Pageh3 from './Pageh3';
import Pageh4 from './Pageh4';
import Pageh5 from './Pageh5';
import Pageh6 from './Pageh6';
import './Slide.css';


class Slide extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      page: 0,
      noScroll: true,
    };
  }

  handleClick() {
    // this.setState({ page: this.state.page + 1 });
  }

  onConfirmXY = () => {
    console.log('in onConfirmXY');


    // window.postMessage('test','*');
    this.setState({
      noScroll: false,
    });
  };

  getSwiper = (params) =>{
    let enrol_activity_price = this.props.info.enrol_activity_price;
    if(!enrol_activity_price){
      return  (
        <Swiper {...params} >
          <section>
            <Pageh2 page={this.state.page} info={this.props.info}/>
          </section>
          <section>
            <Pageh3 page={this.state.page} info={this.props.info}/>
          </section>
          <section>
            <Pageh6 page={this.state.page} info={this.props.info}/>
          </section>

        </Swiper>
      )
    }else{
      return <Swiper {...params} >

        {/*<section className="section page page-1 page-home">*/}
        {/*  <Pageh page={this.state.page} info={this.props.info} onConfirmXY={this.onConfirmXY}/>*/}
        {/*</section>*/}

        <section>
          <Pageh2 page={this.state.page} info={this.props.info}/>
        </section>
        <section>
          <Pageh3 page={this.state.page} info={this.props.info}/>
        </section>
        <section>
          <Pageh4 page={this.state.page} info={this.props.info}/>
        </section>
        <section>
          <Pageh5 page={this.state.page} info={this.props.info}/>
        </section>
        <section>
          <Pageh6 page={this.state.page} info={this.props.info}/>
        </section>

      </Swiper>
    }
  }


  render() {
    const params = {
      height: document.body.clientHeight,
      autoHeight: true,
      direction: 'vertical',
      mousewheel: true,
      slidesPerView: 1,
      styles: {
        backgroundColor: 'rgba(61, 28, 81, 1)',
      },
      on: {
        init: () => {
          this.setState({ page: 2 });
        },
        slideNextTransitionEnd: () => {
          this.setState({ page: this.state.page + 1 });
        },
        slidePrevTransitionEnd: () => {
          this.setState({ page: this.state.page - 1 });
        },
      },
    };
    const styles = {
      section1: {
        // background: `url(./back.jpg) no-repeat top center`,
        // backgroundSize: 'cover',
      },
      section: {
        // background: `url(./back.jpg) no-repeat top center`,
        // backgroundSize: 'cover',
      },
      fadeInUp1s: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
      },
      fadeInUp1_5s: {
        animation: 'x 1.5s',
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
      },
      fadeInUp2s: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
      },
      fadeInUp2_5s: {
        animation: 'x 2.5s',
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
      },
      fadeInUp3s: {
        animation: 'x 3s',
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
      },
    };
    return (
      <div className="Slide">

        <StyleRoot>

          {this.state.noScroll ?
            <Pageh page={this.state.page} info={this.props.info} onConfirmXY={this.onConfirmXY}/> :
            this.getSwiper(params)
          }

        </StyleRoot>
      </div>
    );
  }
}

export default Slide;

import React, { Component } from 'react';
import { fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import './Slide.css';
import './Pageh3.css';
import  dayjs from 'dayjs'

class Pageh3 extends Component {

  nameArea = () =>{
    if(this.props.info.user.name.length >= 6){
      return (
        <div className='uu'>
          <div className='text-row'>
            <div className='text1'>嘿，{this.props.info.user.name} </div>
          </div>
          <div className='text-row'>
            <div className='text1'> 你是第</div>
            <div className='text1 text2'>{this.props.info.user.id}</div>
            <div className='text1'>位星居民</div>
          </div>
        </div>

      )
    }else {
      return  (
        <div className='text-row'>
          <div className='text1'>嘿，{this.props.info.user.name} 你是第</div>
          {/*<div className='text1'>嘿，位星居民位星居 你是第</div>*/}

          <div className='text1 text2'>{this.props.info.user.id}</div>
          <div className='text1'>位星居民</div>
        </div>
      )
    }
  }
  render() {
    let daysDiff = ''
    let createDateString = ''
    if(this.props.info && this.props.info.user){
      daysDiff = dayjs().diff(this.props.info.user.created_at, 'day')
      createDateString = dayjs(this.props.info.user.created_at).format('YYYY年MM月DD日')
    }
    const styles = {
      fadeIn1s: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },
      fadeIn1_5s: {
        animation: 'x 4s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },

    };
    return (
      <StyleRoot>
        <div className='section page pageh-3'>
          {this.props.page === 3 ?<div className='text-area'>
            <div className='title' style={styles.fadeIn1s}>在最好的时光相遇</div>
            <div className='inner-text' style={styles.fadeIn1_5s}>
              {this.nameArea()}
              <div className='text-row'>
                <div className='text1'>不知不觉，我们已经相伴</div>
                <div className='text1 text2'>{daysDiff}</div>
                <div className='text1'>天</div>
              </div>
              <div className='text1'>{createDateString}</div>
              <div className='text1'>你出现在我的世界</div>


            </div>
          </div>:null}
          <div className='light'></div>

          <div className='bg-area'>

            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              {/*<div className='light'/>*/}
              <div className='bg-1'/>
              <div className='qiu'/>

              <div className='stars'>
                <div className='star1'/>
                <div className='star2'/>
                <div className='star3'/>

              </div>
            </div>
          </div>
        </div>


      </StyleRoot>
    );
  }
}

export default Pageh3;

import React, { Component } from 'react';
import { fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import './Slide.css';
import './Pageh4.css';
import dayjs from 'dayjs';

class Pageh4 extends Component {
   getSongLine = (song_name)=>{
    if(song_name.length > 10){
      return  (
        <div className='right-sss'>
          <div className='text1'>你参与了第一首推广的歌</div>
          <div className='text1' style={{color:'white',marginLeft:'4px'}}>{song_name}</div>

        </div>
      )
    }else{
      return <div className='text-row'>
        <div className='text1'>你参与了第一首推广的歌</div>
        <div className='text1' style={{color:'white'}}>{song_name}</div>
      </div>
    }
  }
  render() {
    const windowWidth = window.screen.width;
    console.log('windowWidth is ', windowWidth);
    const styles = {
      fadeIn1s: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },
      fadeIn1_5s: {
        animation: 'x 4s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn'),
      },

    };

    let song_name = '';
    let settle_price = '';
    let created_at = '';
    if (this.props.info && this.props.info.first_activity) {
      let first_activity = this.props.info.first_activity;
      song_name = first_activity.song_name;
      settle_price = first_activity.settle_price/100
      created_at = dayjs(first_activity.created_at).format('YYYY年MM月DD日');
    }
    return (
      <StyleRoot>
        <div className='section page pageh-4'>
          {/*<div className='sandx'></div>*/}

          {/*<div className='light'/>*/}
          {this.props.page === 4 ? <div className='text-area'>
            <div className='title' style={styles.fadeIn1s}>特别的一天</div>
            <div className='inner-text' style={styles.fadeIn1_5s}>
              <div className='text1'>{created_at}</div>
              {this.getSongLine(song_name)}
            </div>
            <div className='section2' style={styles.fadeIn1_5s}>
              <div className='text-row'>
                <div className='text1'>获得了</div>
                <div className='text1 text2'>{settle_price}</div>
                <div className='text1'>元收益</div>
              </div>
              <div className='text1'>这是你在星斗推的第一桶金</div>
              <div className='text1'>自此开启了和我们一起推星致富的征程</div>
            </div>
          </div> : null}

          <div className='bg-area'>
            {/*<div style={{ width: '100%', height: '100%', position: 'relative' }}>*/}
            <div className='light'></div>
            <div className='land-area'>
              <div style={{ width: '100%', height: '100%', position: 'relative' }}>

                <div className='land'>

                </div>
                <div className='zp1'/>
                <div className='zp2'/>
              </div>

            </div>


            <div className='sandx'/>

            {/*<div className='door'/>*/}
            <div className='stars'/>
            <span className="meteor meteor-1"/>

            {/*</div>*/}
          </div>
        </div>


      </StyleRoot>
    );
  }
}

export default Pageh4;

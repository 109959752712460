import React, { Component } from 'react';
import { fadeInUp,fadeIn} from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import './Slide.css';
import './Pageh2.css';
import  dayjs from 'dayjs'

class Pageh2 extends Component {
  render() {
    const todayString = dayjs().format('YYYY年MM月DD日')
    let totolPrice = ''
    if(this.props.info){
       totolPrice = Math.floor(this.props.info.activity_price/1000000) + 'w'

    }
    const windowHeight = window.screen.height
    let textAreaStyle = {
      bottom:'50px'
    }
    let bgAreaStyle = {
      top:'0px'
    }

    if(windowHeight < 700){
      textAreaStyle = {
        bottom: '10px'
      }
       bgAreaStyle = {
        top:'0px'
      }
    }
    const styles = {
      fadeInUp1s: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeIn, 'fadeInUp'),
      },
      fadeInUp1_5s: {
        animation: 'x 2s',
        animationName: Radium.keyframes(fadeIn, 'fadeInUp'),
      },
      fadeInUp2s: {
        animation: 'x 4s',
        animationName: Radium.keyframes(fadeIn, 'fadeInUp'),
      },

    };
    return (
      <StyleRoot>
        <div className='section page pageh-2' style={styles.fadeInUp1s}>
          <div className="meteors">
            <span className="meteor meteor-1"/>
            {/*<span className="meteor meteor-2"/>*/}
          </div>
          <div className='top-bg' style={bgAreaStyle}>
            <div style={{width:'100%',height:'100%',position:'relative'}}>
              <div className='bg0'/>
              {/*<div className='bg1'/>*/}
              {/*<div className='bg2'/>*/}
              <div className='bg3'/>
              {/*<div className='bg4'/>*/}

              <div className='yhy'/>
              <div className='icon1'> </div>
              <div className='icon2'> </div>
              <div className='icon3'> </div>
              <div className='lazu'/>
              <div className='fire'/>
            </div>
          </div>
          {this.props.page === 2 ?<div className='text-area' style={textAreaStyle}>
            <div className='title' style={styles.fadeInUp1s}>这一年</div>
            <div className='section1' style={styles.fadeInUp1_5s}>
              {/*<div className='text1'>截止{todayString}</div>*/}
              <div className='text1'>截止2022年12月30日</div>

              <div className='text-row'>
                <div className='text1'>星斗推共计发单</div>
                {/*<div className='text1 text2'> {this.props.info.activity_count}</div>*/}
                <div className='text1 text2'> 3000+</div>

              </div>
              <div className='text-row'>
                {/*<div className='text1'>入驻视频号</div>*/}
                {/*<div className='text1 text2'> {this.props.info.account_count}</div>*/}
                <div className='text1'>累计注册用户达</div>
                <div className='text1 text2'>10w+</div>
              </div>
              <div className='text-row'>
                <div className='text1'>已发放推广奖励超</div>
                <div className='text1 text2'> 2500w</div>
              </div>
              <div className='section2' style={styles.fadeInUp2s}>
                <div className='text3'>每一次推广的背后</div>
                <div className='text3'>都不仅仅是作品本身</div>
                <div className='text3'>更是代表同类群体情感产生的共鸣</div>
              </div>
            </div>


          </div>:null}

        </div>


      </StyleRoot>
    );
  }
}

export default Pageh2;
